<template>
    <div>
        <bread-crumb :navigationArray="navigationLink"/>
        <section class="white-container container_margin">
            <h5>
              {{ $store.getters.GET_LANG ? 'Для СМИ' : 'For the media' }}
            </h5>
            <tricolor-line class="tricolor_line"/>
            <search-surveys @keyPush="keyPush"/>
            <tabs-header class="card_containet__tabs" :section="announcements" />
            <div class="contain" :class="{'contain__content-list' : getListView}">
                <dynamic-news-card v-for="(announcement, index) in announcements.announce"
                                   :is-list-view="getListView"
                                   :content="announcement"
                                   :key="`announcement${index}`"
                                   class="deep_row"/>
            </div>
        </section>

        <section class="white-container container_margin" id="form_accreditation">
            <h6> {{ $store.getters.GET_LANG ? 'Форма для аккредитации СМИ' : 'Media accreditation form' }} </h6>
            <media-accreditation-form class="form_margin"/>
        </section>

        <section class="white-container">
            <contact-section v-for="(section, index) in getMediaContacts"
                             :key="`contactSection${index}`"
                             :section="section"/>
        </section>
    </div>
</template>
<script>
import BreadCrumb from '@/components/BreadCrumb'
import TricolorLine from '@/components/TricolorLine'
import SearchSurveys from '@/components/activitiesSurveys/SearchSurveys'
import TabsHeader from '@/components/indexPage/TabsHeader'
import MediaAccreditationForm from '@/components/contact/MediaAccreditationForm'
import ContactSection from '@/components/contact/ContactSection'
import DynamicNewsCard from '@/components/newsList/DynamicNewsCard'

export default {
  name: 'ForMedia',
  components: {
    DynamicNewsCard,
    ContactSection,
    BreadCrumb,
    TricolorLine,
    SearchSurveys,
    TabsHeader,
    MediaAccreditationForm
  },
  data () {
    return {
      currentPage: 1,
      announcements: {
        title: 'Анонсы',
        url_link: '/news?tab=Announcements',
        url_name: 'Посмотреть все',
        announce: {}
      }
    }
  },
  mounted () {
    document.title = 'Для СМИ'
    this.$store.commit('setView', false)
    this.getData()
    if (this.$store.getters.getPublicAnnouncements.length === 0) {
      // this.$store.dispatch('getPublicAnnouncements')
      this.publicAnnouncements()
    } else {
      this.setAnnouncements()
    }
    if (this.$route.hash) {
      setTimeout(() => this.scrollFix(this.$route.hash), 1)
    }
  },
  computed: {
    getListView () {
      return this.$store.getters.getListView
    },

    getMediaContacts () {
      console.log(this.$store.getters.getContacts)
      return this.$store.getters.getContacts.filter(contact => contact.title === (this.$store.getters.GET_LANG ? 'Пресс-служба Общественной палаты РФ' : 'Press service of the Public Chamber'))
    },
    getPublicAnnouncements () {
      return this.$store.getters.getPublicAnnouncements
      /*
      .map(announce => {
        return {
          date: announce.date,
          title: announce.title_eng ? (this.$store.getters.GET_LANG ? announce.title : announce.title_eng) : announce.title,
          subtitle: announce.description_eng ? (this.$store.getters.GET_LANG ? announce.description : announce.description_eng) : announce.description,
          photo: announce.preview_photo, // 'announc5.png',
          link: `/announcement/${announce.id}`
        }
      })
      */
    },
    navigationLink () {
      return [
        {
          title: this.$store.getters.GET_LANG ? 'Главная' : 'Main',
          path: '/'
        },
        {
          title: this.$store.getters.GET_LANG ? 'Связаться' : 'Contact',
          path: '/'
        },
        {
          title: this.$store.getters.GET_LANG ? 'Для СМИ' : 'For the media'
        }
      ]
    }
  },
  watch: {
    getPublicAnnouncements () {
      this.setAnnouncements()
    },
    '$store.getters.GET_LANG' () {
      this.getData()
      this.publicAnnouncements()
      this.announcements.title = this.$store.getters.GET_LANG ? 'Анонсы' : 'Announcements'
      this.announcements.url_name = this.$store.getters.GET_LANG ? 'Посмотреть все' : 'View all'
    }
  },
  methods: {
    scrollFix (hashbang) {
      location.href = hashbang
    },
    keyPush (string) {
      this.$store.commit('setFilterKey', string)
      this.publicAnnouncements()
      this.$store.commit('setFilterKey', null)
      /*
      this.announcements.announce = this.getPublicAnnouncements.filter(announce => {
        return (announce.title.includes(string) || announce.subtitle.includes(string))
      })
      */
    },
    setAnnouncements () {
      this.announcements.announce = { ...this.getPublicAnnouncements }
      if (this.$route.hash) {
        setTimeout(() => this.scrollFix(this.$route.hash), 1)
      }
    },
    getData () {
      this.$store.dispatch('getContacts', {
        headers: {
          Localization: this.$store.getters.GET_LANG ? 'ru' : 'eng'
        }
      })
    },
    publicAnnouncements () {
      this.$store.dispatch('getPublicAnnouncements', {
        headers: {
          Localization: this.$store.getters.GET_LANG ? 'ru' : 'eng'
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.deep_row{
  ::v-deep .row {
    justify-content: flex-start !important;
  }
}
.white-container{
    width: auto;
    margin: 0 5rem;
    padding: 2.5rem 2.94rem;
    &__title{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1.81rem;
    }
}
.container_margin{
    margin-bottom: 3.125rem;
}
h5{
    font-weight: 600;
    font-size: 1.38rem;
    line-height: 1.75rem;
    color: #1F3245;
    margin-bottom: 1.81rem;
}
.tricolor_line{
    margin-bottom: 0.75rem;
}

.contain{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    /*margin: 0 -1.06rem -1.87rem -1.06rem;*/

    ::v-deep > *{
        margin-bottom: 1rem;
    }
    &__content-list {
      margin: 0;
      flex-direction: column;
    }
  &::after {
    content: '';
    width: 26.5625rem;
  }
}

.card_containet__tabs{
    margin: 1.44rem 0 1.375rem 0;
    border-bottom: .125rem solid #D5D6D9;
    padding-bottom: .94rem;
}

h6{
    font-weight: 600;
    font-size: 1.13rem;
    line-height: 1.5rem;
    color: #1F3245;
    margin-bottom: 1.88rem;
}

@media screen and (max-width: 768px){
  .white-container{
    width: auto;
    margin: 0 .75rem;
    padding: 1.86rem 1.25rem;
  }
  .contain .containet_block {
    width: 45%;
  }
  .container_margin{
    margin-bottom: 3.125rem;
  }
}
@media screen and (max-width: 420px){
    .white-container{
        width: 100%;
        margin: 0;
        padding: 1.88rem 1rem;
    }
    .container_margin{
        margin-bottom: 3.125rem;
    }
    .contain {
      .containet_block {
        width: 100%;
      }
    }
    .form_margin{
      margin-right: 2rem;
    }
}

</style>
