<template>
    <ul class="filter-chambers">
        <li>
            <input class='input' type="text" v-model="survey" :placeholder="$store.getters.GET_LANG ? 'Поиск' : 'Search'">
            <button type="button" @click="$store.dispatch('setView', false)" class="btn__icon btnSearch"></button>
        </li>
        <li v-show="showButton">
            <button type="button" @click="$store.dispatch('setView', false)" class="btn__icon btnBlock" :class="{'active' : getListView}"></button>
            <button type="button" @click="$store.dispatch('setView', true)" class="btn__icon btnList" :class="{'active' : !getListView}"></button>
        </li>
    </ul>
</template>
<script>
export default {
  name: 'SearchSurveys',
  props: {
    showButton: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      survey: ''
    }
  },
  computed: {
    getListView () {
      return this.$store.getters.getListView
    },
    getTotalPage () {
      return this.$store.getters.getContent.total ? this.$store.getters.getContent.total : 0
    }
  },
  watch: {
    survey (value) {
      this.$emit('keyPush', value)
      // this.$store.commit('setSearchSurvey', value)
    }
  },
  methods: {
    getPage (page) {
      console.log(page)
      this.currentPage = page
    }
  }
}
</script>
<style lang="scss" scoped>
.filter-chambers {
  display: flex;
  justify-content: space-between;
  margin: 2rem 0;

  li {
    display: flex;
    position: relative;
    margin-right: 0.625rem;

    input {
        background: #EEF4FA;
        border-radius: 3px;
    }
  }
  li:nth-child(1) {
    width: 26.5625rem;

    button {
      border: none;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto 0;
    }
  }
}
@media screen and (max-width: 1280px){
  .filter-chambers{
    flex-wrap: wrap;
    justify-content: space-between;
    li:nth-child(1){
      margin-right: 30%;
    }
  }
}
@media screen and (max-width: 768px){
  .filter-chambers{
    flex-wrap: wrap;
    justify-content: space-between;
    li{
      margin-right: 0;
      align-items: center;
    }
    li:nth-child(1){
      margin-right: 0;
      width: 21.35rem;
      input{
        padding: 0.4rem 1.25rem;
      }
    }
  }
}

@media screen and (max-width: 420px){
  .filter-chambers{
    flex-wrap: nowrap;
    li:nth-child(1){
      width: 100%;
    }
    li:nth-child(2){
      // width: 100%;
      width: auto;
      justify-content: flex-end;
    }
  }
}
</style>
